import cx from "classnames";
import { differenceWith, get, isEmpty, isEqual, keysIn, set } from "lodash";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import { Form } from "react-final-form";
import { useEffect, useState} from "react";
import { toast} from "../../../../components";
import { PANWDSModal} from "../../../../components/PANWDSElements";
import { Button, LoadingButton, Button as PANWDSButton, Accordion, Title, Body, Bold, AccordionPanel} from "@panwds/react-ui";
import { dataProvider} from "../../../../dataProvider";
import { usePermissions, useTranslate} from "../../../../customHooks";
import { ReduxActions, ReduxResources} from "../../../../redux";
import { useHistory, useParams} from "react-router-dom";
import { useAppDispatch} from "../../../../app/hooks";
import { FirewallEditComponent} from "../index";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {ExclamationTriangleIcon} from "@panwds/icons";

interface FirewallSettingsState {
    initialRecordValue: any,
    PolicyManagement?: any,
    EndpointManagement?: any
}

const Index = ({ record, classes, featureFlags }: { record: any, classes: any, featureFlags: any }) => {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const params = useParams<{ firewallname: string }>();
    const firewallName = params.firewallname;


    const [fwEditKey, setFwEditKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const initialState: FirewallSettingsState = {
        initialRecordValue: record,
        EndpointManagement: {},
        PolicyManagement: {}
    }
    const [state, setState] = useState<FirewallSettingsState>(initialState);

    const handleAZFormatter = (value: any) => {
        return value.map((item: any) => {
            if(typeof item === "string"){
                const AvailabilityZone = item.split(' ');
                return {"AvailabilityZone": AvailabilityZone[0]};
            }
            return item;
        });
      }

    const getBaseZone = (zoneStr: any) => {
        return zoneStr.split(' ')[0];
    };

    const handleSubnetFormatter = (value: any) => {
        return value.map((item: any) => {
            if(typeof item === "string"){
                return {"SubnetId": item};
            }
            return item;
        });
      }

    useEffect(() => {
        // making future-proof if we need to add more apis to update the initial state
        Promise.all([getPolicyManagement()])
            .then(resp => {
               const [policyManagementResp] = resp;

                let initialValue = {...state.initialRecordValue};

                if (record?.Firewall?.LinkId) initialValue = {
                    ...initialValue,
                    Firewall: {
                        ...initialValue?.Firewall,
                        ...getLinkId({
                            pnLinks: policyManagementResp.pnLinks, cmLinks: policyManagementResp.cmLinks, iType: policyManagementResp.iType
                        })
                    }
                }
                initialValue["PolicyManaged"] = policyManagementResp.iType;

                if (record?.Firewall?.SubnetMappings && record?.Firewall?.EndpointMode === "CustomerManaged") {
                    initialValue = {
                        ...initialValue,
                        Firewall: {
                            ...initialValue?.Firewall,
                            SubnetMappings: state.initialRecordValue?.Firewall?.SubnetMappings.map((zone: any) => {
                                return {"AvailabilityZone": (zone?.AvailabilityZoneId) ? `${zone.AvailabilityZone} (${zone.AvailabilityZoneId})` : zone.AvailabilityZone};
                            })
                        }
                    }
                }

                if (record?.Firewall?.UserID?.Enabled !== undefined) {
                    initialValue = {
                        ...initialValue,
                        Firewall: {
                            ...initialValue?.Firewall,
                            PrivateAccess: {...record?.Firewall?.PrivateAccess, EnablePrivateAccess: record?.Firewall?.UserID.Enabled}
                        }
                    }
                }

                if (!record?.Firewall.Tags) {
                    initialValue = {
                        ...initialValue,
                        Firewall: {
                            ...initialValue?.Firewall,
                            Tags: []
                        }
                    }
                }

                if (record?.Status?.FirewallStatus === 'UPDATING') {
                    initialValue["FirewallUpdating"] = record?.Status?.FirewallStatus === 'UPDATING';
                }

                setState({
                    ...state,
                    PolicyManagement: {
                        pnLinks: policyManagementResp.pnLinks,
                        cmLinks: policyManagementResp.cmLinks,
                        iType: policyManagementResp.iType
                    },
                    initialRecordValue: initialValue
                });
                setLoading(false);

            })
            .catch(e => {
                setLoading(false);
            });

        return () => {
            setState(initialState);
        }
    }, [record]);

    const getPolicyManagement = async () => {

        let apiCall: { resource: string, url?: string, payload?: any } = {
            resource: "settings",
            payload: {panorama: true}
        }
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            }
        }

        return await dataProvider.describe(apiCall.resource, apiCall.url || "", apiCall?.payload || {})
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                let links = !isEmpty(response?.data) ? Object.keys(response?.data?.Links) : [];
                let cmLinks: Array<any> = [];
                let pnLinks: Array<any> = [];
                let iType = 'Rulestack';
                links.map(link => {
                    const value = response.data.Links[link].LinkName ? `${link} (${response.data.Links[link].LinkName})` : link;
                    if (response.data.Links[link]?.hasOwnProperty('CloudManager') && value !== undefined && response.data.Links[link].Status === 'Active') {
                        cmLinks.push({text: value, value: link});
                    }
                    if (response.data.Links[link]?.hasOwnProperty('Panorama') && value !== undefined) {
                        pnLinks.push({text: value, value: link});
                    }
                    if (record.Firewall?.LinkId && record.Firewall?.LinkId === link) {
                        if (response.data.Links[link]?.hasOwnProperty('CloudManager')) {
                            iType = 'Strata';
                        } else if (response.data.Links[link]?.hasOwnProperty('Panorama')) {
                            iType = 'Panorama';

                        }
                    }
                    if(record.Firewall?.LinkId && record.Firewall?.LinkId === "Link-SCM-None") {
                        iType = 'Strata';
                    }
                });
                return {
                    pnLinks,
                    cmLinks,
                    iType
                };
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, {toastId: "settings-describe"});
                return {
                    pnLinks: [], cmLinks: [], iType: 'Rulestack'
                }
            })
    };

    const getLinkId = ({pnLinks, cmLinks, iType}: {pnLinks: any, cmLinks: any, iType: string}) => {
        if(iType === 'Panorama') {
            if(pnLinks) {
                return {"LinkId": pnLinks?.filter((entry: any) => entry?.text?.includes(record?.Firewall?.LinkId))[0]?.text}
            } else {
                return {"LinkId": record?.Firewall?.LinkId}
            }

        } else if(iType === 'Strata') {
            if(cmLinks) {
                if(record.Firewall?.LinkId && record.Firewall?.LinkId === "Link-SCM-None") {
                    return {"CMLinkId": "None", LinkId: null}
                } else {
                    return {"CMLinkId": cmLinks?.filter((entry: any) => entry?.text?.includes(record?.Firewall?.LinkId))[0]?.text, LinkId: null}
                }
            } else {
                return {"CMLinkId": record?.Firewall?.LinkId, LinkId: null}
            }
        }
        return
    }

    const onSubmit = async (values: any, form: any) => {
        values['RuleStackEntry'] = values.RuleStackCandidate;
        let formState = form.getState();

        if (formState.dirty) {
            let calls = [];
            /*need changes for CMLinkId */
            if ((get(formState, "dirtyFields")['Firewall.LinkId'] || get(formState, "dirtyFields")['Firewall.CMLinkId']) && permissions?.AssociateRuleStack) {
                let isLinkIdPanorama = get(formState, "dirtyFields")['Firewall.LinkId']
                setSubmitting(true);
                let payload = {
                    Unlink: isLinkIdPanorama ? (values?.Firewall?.LinkId === "None") : (values?.Firewall?.CMLinkId === "None"),
                    LinkId: isLinkIdPanorama ? values.Firewall.LinkId : values?.Firewall?.CMLinkId,
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
                // dataProvider.update("firewalls", payload).then(async (response: any) => {
                //     history.goBack();
                // }).catch((e: any) => {
                //     setFwEditKey(fwEditKey + 1);
                //     toast.error(e?.error, { toastId: "firewalls-update" });
                // }).finally(() => {
                //     setSubmitting(false)
                // });
            }
            if (get(formState, "dirtyFields")['Firewall.RuleStackName'] && permissions?.AssociateRuleStack) {
                let requestBody = (
                    values?.Firewall?.RuleStackName === "None"
                        ? { "Disassociate": true, ...values.Firewall }
                        : { "Associate": true, ...values.Firewall }
                );
                setSubmitting(true);
                calls.push(dataProvider.update("firewalls", requestBody));
                // dataProvider.update("firewalls", requestBody).then(async (response: any) => {
                //     history.goBack();
                // }).catch((e: any) => {
                //     setFwEditKey(fwEditKey + 1);
                //     toast.error(e?.error, { toastId: "firewalls-update" });
                // }).finally(() => {
                //     setSubmitting(false)
                // });
            }
            if (get(formState, "dirtyFields")['Firewall.Description']) {
                if (!permissions?.UpdateFirewallDescription) {
                    toast.warning(translate("permissions.cantExecute"));
                    return;
                }

                let payload = {
                    Description: values.Firewall.Description,
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
            }
            if (get(formState, "dirtyFields")['Firewall.SubnetMappings']) {
                let comparatorField: string, subnetMapping;
                if(values?.Firewall?.EndpointMode === "CustomerManaged"){
                    subnetMapping = handleAZFormatter(values?.Firewall?.SubnetMappings);
                    comparatorField = "AvailabilityZone";
                } else {
                    subnetMapping = handleSubnetFormatter(values?.Firewall?.SubnetMappings);
                    comparatorField = "SubnetId";
                };

                // Calculate the differences
                let AssociateSubnetMappings = permissions?.AssociateSubnetMappings ? differenceWith(
                    subnetMapping,
                    formState.initialValues.Firewall.SubnetMappings,
                    (newVal: any, oldVal: any) => getBaseZone(newVal[comparatorField]) === getBaseZone(oldVal[comparatorField])
                ) : [];

                let DisassociateSubnetMappings = permissions?.DisassociateSubnetMappings ? differenceWith(
                    formState.initialValues.Firewall.SubnetMappings,
                    subnetMapping,
                    (newVal: any, oldVal: any) => getBaseZone(newVal[comparatorField]) === getBaseZone(oldVal[comparatorField])
                ) : [];

                // Ensure we don't associate and disassociate the same items
                DisassociateSubnetMappings = DisassociateSubnetMappings.filter(disassociateItem =>
                    !AssociateSubnetMappings.some((associateItem: any) =>
                    getBaseZone(associateItem[comparatorField]) === getBaseZone(disassociateItem[comparatorField])
                    )
                );

                // Map the results to ensure only base zone names are sent in the payload
                AssociateSubnetMappings = AssociateSubnetMappings.map(item => ({
                    [comparatorField]: getBaseZone(item[comparatorField])
                }));

                DisassociateSubnetMappings = DisassociateSubnetMappings.map(item => ({
                    [comparatorField]: getBaseZone(item[comparatorField])
                }));

                let payload = {
                    AssociateSubnetMappings,
                    DisassociateSubnetMappings,
                    FirewallName: values?.Firewall?.FirewallName || firewallName,
                    AccountId: values.Firewall.AccountId
                  };

                if (get(formState, "dirtyFields")['Firewall.MultiVpcEnable']) {
                    //@ts-ignore
                    calls.push(dataProvider.update("firewalls", { ...payload, MultiVpcEnable: values?.Firewall?.MultiVpcEnable }))
                } else {
                    //@ts-ignore
                    calls.push(dataProvider.update("firewalls", payload));
                }
            }
            if (get(formState, "dirtyFields")['Firewall.MultiVpcEnable']) {
                if (!permissions?.AssociateSubnetMappings) {
                    toast.warning(translate("permissions.cantExecute"));
                    return;
                }
                let payload = {
                    MultiVpcEnable: values.Firewall.MultiVpcEnable,
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId
                };
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
            }
            if (keysIn(formState.dirtyFields).some((str:string) => str.includes("Firewall.UserID")) ||
                keysIn(formState.dirtyFields).some((str:string) => str.includes("Firewall.PrivateAccess"))) {
                let payload = {
                    UserID: values.Firewall.UserID,
                    PrivateAccess: values.Firewall.PrivateAccess,
                };

                if(payload?.PrivateAccess?.EnablePrivateAccess !== undefined){
                    delete payload['PrivateAccess']['EnablePrivateAccess'];
                }

                //@ts-ignore
                calls.push(dataProvider.update("firewalls", { "Features": payload, "FirewallName": values ? values.Firewall.FirewallName : firewallName, AccountId: values.Firewall.AccountId }));
            }
            if (get(formState, "dirtyFields")['Firewall.Tags']) {
                const initialValues = formState.initialValues.Firewall.Tags || [];
                const initialTags: any = {};
                initialValues.map((tag: any) => {
                    initialTags[tag.Key] = tag.Value;
                });
                const currentKeys = values.Firewall.Tags.map((tag: any) => tag.Key);
                const newTags = values.Firewall.Tags.filter((tag: any) => !(initialTags[tag.Key] && initialTags[tag.Key] === tag.Value));
                const deleteTags = initialValues
                    .filter((tag: any) => !currentKeys.includes(tag.Key))
                    .map((tag: any) => tag.Key);
                if (newTags.length) {
                    const newTagsPayload = {
                        resourceType: 'ngfirewalls',
                        resourceName: values.Firewall.FirewallName,
                        payload: { "Tags": newTags },
                        AccountId: values.Firewall.AccountId
                    };
                    //@ts-ignore
                    calls.push(dataProvider.update("tags", newTagsPayload));
                }
                if (deleteTags.length) {
                    const deleteTagsPayload = {
                        resourceType: 'ngfirewalls',
                        resourceName: values.Firewall.FirewallName,
                        payload: { "TagKeys": deleteTags },
                        AccountId: values.Firewall.AccountId
                    };
                    //@ts-ignore
                    calls.push(dataProvider.delete("tags", deleteTagsPayload));
                }
            }
            if (get(formState, "dirtyFields")['Features.SecurityZones'] ||
                get(formState, "dirtyFields")['Firewall.EgressNAT.Enabled'] ||
                get(formState, "dirtyFields")['Firewall.EgressNAT.Settings.IPPoolType']) {
                const initialValueEgressNat = formState?.initialValues?.Firewall?.EgressNAT;
                const payload = {
                    FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    AccountId: values.Firewall.AccountId,
                    Features: values.Features || {}
                };
                if (
                    initialValueEgressNat &&
                    (
                        initialValueEgressNat?.Enabled !== values?.Firewall?.EgressNAT?.Enabled ||
                        initialValueEgressNat?.Settings?.IPPoolType !== values?.Firewall?.EgressNAT?.Settings?.IPPoolType
                    )
                ) {
                    payload.Features.EgressNAT = {...values?.Firewall?.EgressNAT, Enabled: values?.Firewall?.EgressNAT.Enabled};
                }
                //@ts-ignore
                calls.push(dataProvider.update("firewalls", payload));
            }
            if (calls.length !== 0) {
                setSubmitting(true);
                Promise.all(calls).then((response: any) => {
                    dispatch(ReduxActions.updateResource({ resource: ReduxResources.FIREWALL })({
                        FirewallName: values ? values.Firewall.FirewallName : firewallName,
                        AccountId: values.Firewall.AccountId
                    }));
                    if (response[0] && response[0].data) {
                        history.goBack();
                    } else {
                        setFwEditKey(fwEditKey + 1);
                        toast.error(response?.error, { toastId: "firewall-edit" });
                    }
                }).catch((e: any) => {
                    {/*TODO:Need to remove this logic from here*/ }
                    // dispatch(ReduxActions.updateResource({resource: ReduxResources.FIREWALL})({
                    //     FirewallName: values ? values.Firewall.FirewallName : firewallName,
                    //     AccountId: values.Firewall.AccountId
                    // }));
                    toast.error(e?.error, { toastId: "firewall-edit" });
                    history.goBack();
                    //setFwEditKey(fwEditKey + 1);
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }
    };

    const Toolbar = (toolbarProps: any) => {
        const { pristine, values } = toolbarProps;
        const canEdit = permissions.UpdateFirewallDescription;

        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitting || pristine || values?.FirewallUpdating}
                    onClick={() => history.goBack()}
                    dataMetrics="cloudngfw-firewall-edit-cancel-button"
                >
                    Cancel
                </PANWDSButton>
                <LoadingButton
                    dataResult={{
                        loading: !!submitting,
                        success: true
                    }}
                    size="md"
                    appearance="primary"
                    type="submit"
                    disabled={submitting || pristine || !canEdit || values?.FirewallUpdating}
                    dataMetrics="cloudngfw-firewall-edit-save-button"
                >
                    Save
                </LoadingButton>
            </div>
        );
    };

    return (
        <div className={cx(isEmpty(record) || submitting ? classes.loadingBlur : "")}>
            <CircularLoader loading={isEmpty(record) || submitting || loading} />
            {!loading && <>
                <Form
                    key={fwEditKey}
                    onSubmit={onSubmit}
                    initialValues={state.initialRecordValue}
                    render={formProps => {
                        let { handleSubmit, values } = formProps;
                        const [confirmDisassociateModal, setConfirmDisassociateModal] = useState({ open: false, callback: () => { } });
                        const [confirmationModal, setConfirmationModal] = useState({ open: false, callback: () => { } });
                        const [formDirtyConfirmationModal, setFormDirtyConfirmationModal] = useState({ open: false, callback: () => { } });
                        const preSubmit = (event: any) => {
                            event.preventDefault()
                            if (values?.Firewall?.RuleStackName === "None" && !record?.Firewall?.GlobalRuleStackName) { // disassociate workflow
                                setConfirmDisassociateModal({
                                    open: true,
                                    callback: () => handleSubmit({ ...values })
                                });
                            } else if(values.PolicyManaged === "Strata" && values?.Firewall?.CMLinkId === "None") {
                                setConfirmationModal({
                                    open: true,
                                    callback: () => handleSubmit({ ...values })
                                });
                            } else { // normal update workflow
                                setFormDirtyConfirmationModal({
                                    open: true,
                                    callback: () => handleSubmit(values)
                                });
                            }
                        }

                        return (
                            <>
                                <form onSubmit={preSubmit} defaultValue={values}>

                                    <FirewallEditComponent.FirewallSettingsComponent.FirewallBanner/>

                                    <Accordion addClassName={classes.pantileContainer}
                                               allowMultipleOpen={true}
                                               allowNoneOpen={true}
                                    >
                                        <AccordionPanel title={translate(`resources.firewalls.fields.General`)}>
                                            <FirewallEditComponent.FirewallSettingsComponent.General
                                                record={record}
                                            />
                                        </AccordionPanel>
                                        <AccordionPanel title={translate(`resources.firewalls.fields.PolicyManagement`)}>
                                            <FirewallEditComponent.FirewallSettingsComponent.PolicyManagement
                                                record={record}
                                                PolicyManagementState={state.PolicyManagement}
                                            />
                                        </AccordionPanel>
                                        {(featureFlags?.UserID == true) && ((state.PolicyManagement.iType === 'Panorama') || 
                                            (state.PolicyManagement.iType === 'Rulestack')) &&
                                            <AccordionPanel title={translate(`resources.firewalls.fields.PrivateAccess`)}>
                                                <FirewallEditComponent.FirewallSettingsComponent.PrivateAccess
                                                record={record}
                                            />
                                            </AccordionPanel>
                                        }
                                        {process.env.REACT_APP_ENABLE_EGRESS_NAT ===
                                            "true" && (
                                                <AccordionPanel title={translate(`resources.firewalls.fields.EgressNAT.Title`)}>
                                                    <FirewallEditComponent.FirewallSettingsComponent.EgressNat />
                                                </AccordionPanel>
                                            )}
                                        <AccordionPanel title={translate(`resources.firewalls.fields.EndpointManagement`)}
                                            caption={translate(`resources.firewalls.fields.EndpointManagementSubtitle`)}>
                                            <FirewallEditComponent.FirewallSettingsComponent.EndpointManagement
                                                record={record}
                                            />
                                        </AccordionPanel>
                                    </Accordion>
                                    <Toolbar {...formProps} />
                                </form>
                                <PANWDSModal
                                    title={translate(`resources.firewalls.modal.disassociateModalTitle`)}
                                    onClose={() => setConfirmDisassociateModal({ open: false, callback: () => { } })}
                                    isOpen={confirmDisassociateModal.open}
                                    size="sm"
                                    dataTestId="confirm-rs-disassociate-modal"
                                    dataMetrics="confirm-rs-disassociate-modal"
                                    modalBody={
                                        <div className={classes.modalBody}>
                                            {translate(
                                                `resources.firewalls.modal.disassociateModalDescription`
                                            )}
                                        </div>
                                    }
                                    modalFooter={{
                                        footerRequired: true,
                                        cancelButtonRequired: true,
                                        featureSpecificButton: (
                                            <Button
                                                appearance="primary"
                                                onClick={() =>
                                                    confirmDisassociateModal.callback()
                                                }
                                                dataMetrics="firewall-disassociate-rs-confirm-btn"
                                            >
                                                {translate(
                                                    `resources.firewalls.modal.confirm`
                                                )}
                                            </Button>
                                        ),
                                    }}
                                />
                                <PANWDSModal
                                    title={translate(
                                        `resources.firewalls.modal.warning`
                                    )}
                                    onClose={() =>
                                        setConfirmationModal({
                                            open: false,
                                            callback: () => {},
                                        })
                                    }
                                    isOpen={confirmationModal.open}
                                    size="sm"
                                    dataTestId="cloudngfw-scm-no-link-modal"
                                    dataMetrics="cloudngfw-scm-no-link-modal"
                                    modalBody={
                                        <div className={classes.modalBody}>
                                            {translate(
                                                `resources.firewalls.modal.scmConfirm`
                                            )}
                                        </div>
                                    }
                                    modalFooter={{
                                        footerRequired: true,
                                        cancelButtonRequired: true,
                                        featureSpecificButton: (
                                            <Button
                                                appearance="primary"
                                                onClick={() =>
                                                    confirmationModal.callback()
                                                }
                                                dataMetrics="firewall-disassociate-scm-confirm-btn"
                                            >
                                                {translate(
                                                    `resources.firewalls.modal.confirm`
                                                )}
                                            </Button>
                                        ),
                                    }}
                                />
                                <PANWDSModal
                                    title={
                                        <Title level={1} addClassName="tw-flex tw-items-center tw-gap-1" size={"xxs"}>
                                            <ExclamationTriangleIcon size="md" className={classes.pendingColor}/>
                                            {translate("resources.firewalls.modal.warning")}
                                        </Title>
                                    }
                                    onClose={() =>
                                        setFormDirtyConfirmationModal({
                                            open: false,
                                            callback: () => {},
                                        })
                                    }
                                    style={{minWidth: '640px', minHeight: '256px'}}
                                    isOpen={formDirtyConfirmationModal.open}
                                    size="sm"
                                    dataTestId="cloudngfw-confirm-save-modal"
                                    dataMetrics="cloudngfw-confirm-save-modal"
                                    modalBody={
                                        <div className="tw-p-4">
                                            <Body size={"md"} style={{paddingBottom: '0.5rem'}}>
                                                <Bold appearance={"semibold"}>
                                                    {`${translate("resources.firewalls.modal.submitModalBodyTitle")} (${firewallName})`}
                                                </Bold>
                                            </Body>
                                            <Body size={"md"} addClassName="tw-pb-3 tw-pt-3">
                                                {translate("resources.firewalls.modal.submitModalBodyDesc")}
                                            </Body>
                                        </div>
                                    }
                                    modalFooter={{
                                        footerRequired: true,
                                        cancelButtonRequired: true,
                                        featureSpecificButton: (
                                            <Button
                                                appearance="primary"
                                                onClick={() => {
                                                    formDirtyConfirmationModal.callback();
                                                    setFormDirtyConfirmationModal({
                                                        open: false,
                                                        callback: () => {},
                                                    });
                                                }}
                                                dataMetrics="firewall-update-form-dirty-confirm-btn"
                                            >
                                                {translate(
                                                    `resources.firewalls.modal.confirm`
                                                )}
                                            </Button>
                                        ),
                                    }}
                                />
                            </>
                        );
                    }} />
                <FirewallEditComponent.FirewallSettingsComponent.ManageEgressNatTrafficContainer />
            </>}
        </div>
    );
};

export default Index;
